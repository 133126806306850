import React from 'react'
import { Box, Typography } from '@mui/material'
import Timeline from '@mui/lab/Timeline'
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import { BUILDER_CDN } from '../../../../constants/endpoints'
import ProvenIcon from '../../proven-icon'
import { Image } from 'components/builder/blocks/shared/Image'

const ITEMS = [
  {
    icon: 'ingredient-evelolution'
  },
  {
    icon: 'changes-to-your-skin',
    title: 'Ingredient Evolution',
    description:
      'Over time, your skin gets accustomed to active ingredients. We increase their concentration gradually, in keeping with your skin’s tolerance, for continuous improvement.'
  },
  {
    icon: 'your-lifestyle',
    title: 'Changes to your skin',
    description:
      'Noticing fewer breakouts or new fine lines? As your skin texture changes and improves over time, formulas adjust to address any new needs you identify. '
  },
  {
    icon: 'seasonal-changes',
    title: 'Your Lifestyle',
    description:
      'Big life changes such as more frequent travel, a move, or a pregnancy? Simply update your profile and your formula will be adapted for your new lifestyle.'
  },
  {
    icon: 'your-preferences',
    title: 'Seasonal Changes',
    description:
      'Formulas adapt to protect your skin against shifts in temperature, humidity and sun exposure.'
  },
  {
    icon: 'your-feedback',
    title: 'Your Preferences',
    description:
      'You can log in anytime to further customize your treatments to meet your unique needs. Whether you want higher SPF in your day moisturizer, \n' +
      'a lighter night cream, or anything in between.'
  },
  {
    title: 'Your Feedback',
    description:
      'A more potent night cream, a milder cleanser or a more hydrating moisturizer, tell us your thoughts and we’ll tweak your next set to best fit you.'
  }
]

const YOUR_LIFESTYLE_INDEX = 3

const isFirst = number => number === 0

function ItemsMobile() {
  return (
    <Box
      sx={{
        position: 'relative',
        mt: 26
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          left: ['calc(50% - 120px)', 'calc(50% - 120px)', 'calc(50%)'],
          top: [-92, -92],
          transform: ['translate(-50%, -50%) rotate(68.96deg)'], // center image and rotate
          width: 320 * 0.1885,
          height: [320]
        }}
      >
        <Image
          lazy
          image={`${BUILDER_CDN}9be7b3a343374be9b104de2868ae6f69%2F0389275c37a74795b6d20aebbb899ac4`}
          sx={{
            filter: 'drop-shadow(-90px 100px 70px rgba(0, 0, 0, 0.1))' // add shadow to image
          }}
          aspectRatio={0.1885}
        />
      </Box>
      <Timeline
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0
          }
        }}
      >
        {ITEMS.map((item, i) => (
          <TimelineItem
            key={`ing${i}`}
            position={'right'}
            sx={{
              minHeight: 40
            }}
          >
            <TimelineSeparator
              sx={{
                display: isFirst(i) ? 'none' : 'flex',
                color: 'gray.middleGray'
              }}
            >
              <TimelineDot
                sx={{
                  m: 0,
                  p: 0,
                  border: 'none',
                  position: 'relative',
                  height: '20px',
                  width: '18px'
                }}
              >
                {!isFirst(i) && (
                  <Image
                    lazy
                    src={`${BUILDER_CDN}9be7b3a343374be9b104de2868ae6f69%2Fecb28aa11a324cd09a88fb94aa8ade47`}
                    aspectRatio={0.9}
                  />
                )}
              </TimelineDot>
              <TimelineConnector
                sx={{
                  opacity: 0.3
                }}
              />
            </TimelineSeparator>
            <TimelineContent
              sx={{
                py: [0],
                pl: isFirst(i) ? 4 : 2
              }}
            >
              <Box>
                {item.title && (
                  <Typography
                    variant="h4"
                    sx={{
                      display: 'block',
                      mb: [1],
                      textAlign: 'left'
                    }}
                  >
                    {item.title}
                  </Typography>
                )}
                {item.description && (
                  <Typography
                    variant="body2"
                    sx={{
                      display: 'block',
                      mt: [1],
                      mb: [7],
                      textAlign: 'left'
                    }}
                  >
                    {item.description}
                  </Typography>
                )}
                {i === YOUR_LIFESTYLE_INDEX && (
                  <Box
                    sx={{
                      position: 'relative',
                      minHeight: 160
                    }}
                  >
                    <Box
                      sx={{
                        position: 'absolute',
                        right: ['calc(50% - 120px)', 'calc(50% - 120px)', 'calc(50%)'],
                        top: [60, 60],
                        transform: ['translate(-50%, -50%) rotate(-66.7deg)'], // center image and rotate
                        width: 320 * 0.234,
                        height: [320]
                      }}
                    >
                      <Image
                        lazy
                        src={`${BUILDER_CDN}9be7b3a343374be9b104de2868ae6f69%2F4e8f010a0bc54a879aea72f174cc2aca`}
                        sx={{
                          filter: 'drop-shadow(-50px 100px 70px rgba(0, 0, 0, 0.1))' // add shadow to image
                        }}
                        aspectRatio={0.2034}
                      />
                    </Box>
                  </Box>
                )}
                {item.icon && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'left',
                      width: '100%',
                      mb: [2]
                    }}
                  >
                    <ProvenIcon size="1.6rem" type="kit" name={item.icon} />
                  </Box>
                )}
              </Box>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </Box>
  )
}

ItemsMobile.propTypes = {}

export default ItemsMobile
