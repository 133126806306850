import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Dialog, Typography } from '@mui/material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { Image } from 'components/builder/blocks/shared/Image'
import { createProvenTheme } from '../../../../styles/theme-proven'

const theme = createProvenTheme()

function Influencer({
  name,
  focus,
  portfolioImage,
  previewUrl,
  videoUrl,
  youtubeUrl,
  profilePhotoUrl
}) {
  const [isVideoModalOpened, setIsVideoModalOpened] = useState(false)

  const handleClose = () => {
    setIsVideoModalOpened(false)
  }

  // useEffect(() => {
  //   if (isVideoModalOpened) {
  //     setTimeout(() => {
  //       const videoElement = document.getElementById('mux-influencer-video')
  //       videoElement.addEventListener(
  //         'webkitendfullscreen',
  //         function () {
  //           handleClose()
  //         },
  //         false
  //       )
  //     }, 1500)
  //   }
  // }, [isVideoModalOpened])

  return (
    <>
      <Box
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          setIsVideoModalOpened(true)
        }}
        sx={{
          position: 'relative',
          width: '267px',
          height: '468px',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          borderRadius: 2,
          cursor: 'pointer',
          overflow: 'hidden',
          boxShadow: '5px 9px 10px 0 rgb(0 0 0 / 10%)'
        }}
      >
        <Box sx={{ width: '100%', height: '100%', position: 'relative' }}>
          <Image lazy={false} image={previewUrl} aspectRatio={0.5625} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: '48px',
            position: 'absolute',
            top: 16,
            left: 0,
            px: 2,
            width: '100%'
          }}
        >
          <Box
            sx={{
              flex: 'none',
              borderRadius: '50%',
              width: '48px',
              height: '48px',
              backgroundImage: portfolioImage ? `url(${portfolioImage})` : profilePhotoUrl,
              backgroundSize: 'cover',
              backgroundPosition: 'center'
            }}
          />
          <Box sx={{ flex: 1, ml: '9px' }}>
            <Box component={Typography} variant="footnote" sx={{ color: theme.palette.gray.white }}>
              {name}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            display: 'flex',
            alignItems: 'center',
            height: '64px',
            width: '100%',
            backgroundColor: theme.palette.gray.white,
            px: 2,
            py: '14px'
          }}
        >
          <Box
            component={Typography}
            variant="footnote"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              color: theme.palette.gray.elysian
            }}
          >
            {focus}
          </Box>
        </Box>
      </Box>
      <Dialog maxWidth="300px" open={isVideoModalOpened} onClose={handleClose}>
        <Box>
          <Box
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: '5px',
              cursor: 'pointer',
              opacity: '0.5',
              right: '5px',
              zIndex: 1,
              '&:hover': {
                opacity: '1'
              }
            }}
          >
            <CloseOutlinedIcon />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              '& > div': { display: 'flex', flexDirection: 'row' },
              '& iframe': {
                maxWidth: 'calc(100vw - 64px)',
                maxHeight: 'calc(100vh - 64px)'
              }
            }}
          >
            {youtubeUrl ? (
              <iframe
                width="350"
                height="700"
                src={`${youtubeUrl.trim()}?autoplay=1`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            ) : (
              <video src={videoUrl} autoPlay muted width="300" height="700" />
            )}
            {/* <MuxVideo
              style={{ height: 'unset', width: '300px' }}
              playbackId={muxPlaybackId}
              metadata={{
                video_id: 'influencer',
                video_title: `${name}'s review`
              }}
              streamType="on-demand"
              autoPlay
              id="mux-influencer-video"
            /> */}
          </Box>
        </Box>
      </Dialog>
    </>
  )
}

Influencer.propTypes = {
  name: PropTypes.string.isRequired,
  focus: PropTypes.string.isRequired,
  portfolioImage: PropTypes.string.isRequired
}

export default Influencer
