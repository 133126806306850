import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import propTypes from 'prop-types'
import debounce from 'lodash/debounce'
import './styles.scss'
import ProvenButton from '../proven-button'
import { ExpandLessRounded } from '@mui/icons-material'
import { useLocation } from 'react-router-dom'

const mockOptions = [
  {
    title: 'Option 1',
    link: '/test'
  },
  {
    title: 'Option 1',
    link: '/test'
  },
  {
    title: 'Option 1',
    link: '/test'
  },
  {
    title: 'Option 1',
    link: '/test'
  },
  {
    title: 'Option 1',
    link: '/test'
  }
]

function DropdownButton(props) {
  const [open, setOpen] = useState(false)
  const location = useLocation()
  useEffect(() => {
    handleClose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const handleOpen = debounce(() => {
    setOpen(true)
    props.onOpen && props.onOpen()
  }, 300)

  const handleClose = debounce(() => {
    setOpen(false)
    props.onClose && props.onClose()
  }, 300)

  const dropdownStyles = classnames('dropdown', {
    open: open,
    closed: !open,
    'from-right': props.dropdownAtLeftSide,
    'variant-common': props.variant === 'common',
    'variant-dashboard': props.variant === 'dashboard'
  })

  const dropdownButtonStyles = classnames('dropdown-button', {
    'variant-dashboard': props.variant === 'dashboard'
  })

  return (
    <div
      styleName={dropdownButtonStyles}
      onMouseEnter={handleOpen}
      onMouseLeave={handleClose}
      onClick={handleOpen}
    >
      {!props.customButtom ? (
        <ProvenButton
          {...props.buttonProps}
          textColor={props.buttonProps?.textColor ?? '#5D6469'}
          onClick={handleOpen}
          renderContent={() => (
            <div styleName="button-content">
              <span>{props.buttonProps.text}</span>
              <ExpandLessRounded
                style={{
                  color: '#918E8E',
                  transform: `rotate(${open ? '0deg' : '180deg'})`,
                  transition: 'transform .3s ease-in-out'
                }}
              />
            </div>
          )}
        />
      ) : (
        <div styleName="custom-button">{props.customButtom}</div>
      )}

      <div styleName="space"></div>
      <div styleName={dropdownStyles}>
        <nav>{props.children}</nav>
      </div>
    </div>
  )
}

DropdownButton.defaultProps = {
  options: mockOptions,
  buttonProps: {
    text: 'Button text'
  },
  dropdownAtLeftSide: false,
  variant: 'common'
}

DropdownButton.propTypes = {
  buttonProps: propTypes.object,
  options: propTypes.array,
  customButtom: propTypes.node,
  onOpen: propTypes.func,
  onClose: propTypes.func,
  dropdownAtLeftSide: propTypes.bool,
  variant: propTypes.oneOf(['common', 'dashboard'])
}

export default DropdownButton
