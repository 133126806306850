import React from 'react'
import propTypes from 'prop-types'
import StarIcon from '@mui/icons-material/Star'
import StarHalfIcon from '@mui/icons-material/StarHalf'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import { ThemeProvider } from '@mui/material/styles'
import { createProvenTheme } from '../../../../styles/theme-proven'

const theme = createProvenTheme()

function Star({ size, gutter, type, color }) {
  const starStylesFromProps = {
    fontSize: `${size}px`,
    marginRight: `${gutter}px`,
    color: color
  }

  return (
    <ThemeProvider theme={theme}>
      {type === 'empty' ? (
        <StarBorderIcon sx={starStylesFromProps} />
      ) : type === 'half' ? (
        <StarHalfIcon sx={starStylesFromProps} />
      ) : (
        <StarIcon sx={starStylesFromProps} />
      )}
    </ThemeProvider>
  )
}

Star.defaultProps = {
  size: 24,
  gutter: 2,
  type: 'full',
  color: '#256376'
}

Star.propTypes = {
  size: propTypes.number,
  gutter: propTypes.number,
  type: propTypes.oneOf(['full', 'half', 'empty']),
  color: propTypes.string
}

export default Star
