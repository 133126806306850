import React from 'react'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import { Drawer } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import { closeLoginSlide } from '../../../actions/checkout.actions'
import { clearAuth, clearPasswordlessAuth } from 'actions/auth.actions'
import Login from '../../pages/login'
import { ThemeProvider } from '@mui/material/styles'
import { createProvenTheme } from '../../../styles/theme-proven'

const themeProven = createProvenTheme()

export default function LoginPanel() {
  const history = useHistory()
  const isOpenFromRoute = _.get(history, 'location.state.openLoginSlide')
  const { isWelcomeBackPage } = useSelector(state => state.app)
  const dispatch = useDispatch()
  const isQuizFlow = useRouteMatch('/quiz/email')

  const isOpen = useSelector(state => {
    const hasAuthError = state.auth?.error || state.auth?.passwordless?.error
    const { openLoginSlide } = state.checkout

    const { togglingEmailPassword = false } = openLoginSlide || {}

    // Don't open login slider in quiz flow
    // and do not open on the welcome back pages, unless it's toggling the EmailPasswordForm
    return (
      !isQuizFlow &&
      !(isWelcomeBackPage && !togglingEmailPassword) &&
      (openLoginSlide || hasAuthError)
    )
  })

  const handleClose = () => {
    dispatch(closeLoginSlide())
    if (isOpenFromRoute) history.replace({ ...history.location, state: {} })
    dispatch(clearAuth())
    dispatch(clearPasswordlessAuth())
  }

  return (
    <ThemeProvider theme={themeProven}>
      <Drawer anchor="right" open={isOpen || isOpenFromRoute} onClose={handleClose}>
        <Login onClose={handleClose} redirect="/account/congratulations" />
      </Drawer>
    </ThemeProvider>
  )
}
