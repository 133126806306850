import React from 'react'
import propTypes from 'prop-types'
import Star from './star'
import './styles.scss'

function FiveStarsRating({ size, rating, ratingOutOf, color }) {
  let ratingOnScaleOfFive = rating
  if (ratingOutOf !== 5) {
    ratingOnScaleOfFive = (rating * 5) / ratingOutOf
  }

  return (
    <div styleName="five-stars">
      {Array(5)
        .fill('item')
        .map((item, i) => {
          const starValue = i + 1
          const remainder = starValue - ratingOnScaleOfFive

          let type = 'full'
          if (remainder >= 0.25 && remainder <= 0.75) {
            type = 'half'
          } else if (remainder > 0.75) {
            type = 'empty'
          }

          return <Star size={size} key={i} type={type} color={color} />
        })}
    </div>
  )
}

FiveStarsRating.defaultProps = {
  rating: 4.5,
  ratingOutOf: 5
}

FiveStarsRating.propTypes = {
  size: propTypes.number,
  rating: propTypes.number,
  ratingOutOf: propTypes.number,
  color: propTypes.string
}

export default FiveStarsRating
