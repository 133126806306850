import React from 'react'
import { Box, Typography } from '@mui/material'
import Timeline from '@mui/lab/Timeline'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import { BUILDER_CDN } from '../../../../constants/endpoints'
import ProvenIcon from '../../proven-icon'
import { Image } from 'components/builder/blocks/shared/Image'

const ITEMS = [
  {},
  {
    icon: 'ingredient-evelolution',
    title: 'Ingredient Evolution',
    description:
      'Over time, your skin gets accustomed to active ingredients. We increase their concentration gradually, in keeping with your skin’s tolerance, for continuous improvement.'
  },
  {
    icon: 'changes-to-your-skin',
    title: 'Changes to your skin',
    description:
      'Noticing fewer breakouts or new fine lines? As your skin texture changes and improves over time, formulas adjust to address any new needs you identify. '
  },
  {
    icon: 'your-lifestyle',
    title: 'Your Lifestyle',
    description:
      'Big life changes such as more frequent travel, a move, or a pregnancy? Simply update your profile and your formula will be adapted for your new lifestyle.'
  },
  {
    icon: 'seasonal-changes',
    title: 'Seasonal Changes',
    description:
      'Formulas adapt to protect your skin against shifts in temperature, humidity and sun exposure.'
  },
  {
    icon: 'your-preferences',
    title: 'Your Preferences',
    description:
      'You can log in anytime to further customize your treatments to meet your unique needs. Whether you want higher SPF in your day moisturizer, \n' +
      'a lighter night cream, or anything in between.'
  },
  {
    icon: 'your-feedback',
    title: 'Your Feedback',
    description:
      'A more potent night cream, a milder cleanser or a more hydrating moisturizer, tell us your thoughts and we’ll tweak your next set to best fit you.'
  }
]

const isEven = number => number % 2 === 0

function ItemsDesktop() {
  return (
    <Box
      sx={{
        position: 'relative',
        mt: 2
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          right: [
            'calc(50% - 120px)',
            'calc(50% - 120px)',
            'calc(50% - 380px)',
            'calc(50% - 620px)'
          ],
          top: [-92, -92, 300],
          width: [320, 320, 500, 663].map(h => h * 0.1885),
          height: [320, 320, 500, 663],
          transform: ['translate(-50%, -50%) rotate(-18.47deg)'] // center image and rotate
        }}
      >
        <Image
          lazy
          image={`${BUILDER_CDN}9be7b3a343374be9b104de2868ae6f69%2F0389275c37a74795b6d20aebbb899ac4`}
          sx={{
            filter: 'drop-shadow(-50px 100px 70px rgba(0, 0, 0, 0.1))' // add shadow to image
          }}
          aspectRatio={0.1885}
        />
      </Box>
      <Timeline sx={{}}>
        {ITEMS.map((item, i) => (
          <TimelineItem
            key={`ing${i}`}
            position="alternate"
            sx={{
              minHeight: 100
            }}
          >
            <TimelineSeparator
              sx={{
                color: 'gray.middleGray'
              }}
            >
              <TimelineDot
                sx={{
                  m: 0,
                  p: 0,
                  border: 'none',
                  position: 'relative',
                  height: '20px',
                  width: '18px'
                }}
              >
                {i !== 0 && (
                  <Image
                    lazy
                    src={`${BUILDER_CDN}9be7b3a343374be9b104de2868ae6f69%2Fecb28aa11a324cd09a88fb94aa8ade47`}
                    aspectRatio={0.9}
                  />
                )}
              </TimelineDot>
              <TimelineConnector
                sx={{
                  opacity: 0.3
                }}
              />
            </TimelineSeparator>
            <TimelineContent
              sx={{
                py: [0],
                pl: 2
              }}
            >
              <Box
                sx={{
                  position: 'relative',
                  maxWidth: [280, 280, 280, 392],
                  ml: isEven(i) ? 0 : 'auto',
                  mr: isEven(i) ? 'auto' : 0
                }}
              >
                {item.icon && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: -50,
                      display: 'flex',
                      justifyContent: isEven(i) ? 'left' : 'right',
                      width: '100%',
                      mb: [2]
                    }}
                  >
                    <ProvenIcon type="kit" name={item.icon} />
                  </Box>
                )}
                {item.title && (
                  <Typography
                    variant="h4"
                    sx={{
                      display: 'block',
                      mb: [1],
                      textAlign: isEven(i) ? 'left' : 'right'
                    }}
                  >
                    {item.title}
                  </Typography>
                )}
                {item.description && (
                  <Typography
                    variant="body2"
                    sx={{
                      display: 'block',
                      mt: [1],
                      mb: [7, 7, 10],
                      textAlign: isEven(i) ? 'left' : 'right'
                    }}
                  >
                    {item.description}
                  </Typography>
                )}
              </Box>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
      <Box
        sx={{
          position: 'absolute',
          left: [
            'calc(50% - 120px)',
            'calc(50% - 120px)',
            'calc(50% - 410px)',
            'calc(50% - 580px)'
          ],
          bottom: [60, 60, 20, -270],
          transform: ['translate(-50%, -50%) rotate(20.85deg)'], // center image and rotate
          width: [320, 320, 420, 532].map(h => h * 0.2034),
          height: [320, 320, 420, 532]
        }}
      >
        <Image
          lazy
          src={`${BUILDER_CDN}9be7b3a343374be9b104de2868ae6f69%2F4e8f010a0bc54a879aea72f174cc2aca`}
          aspectRatio={0.2034}
          sx={{
            filter: 'drop-shadow(-50px 100px 70px rgba(0, 0, 0, 0.1))' // add shadow to image
          }}
        />
      </Box>
    </Box>
  )
}

ItemsDesktop.propTypes = {}

export default ItemsDesktop
