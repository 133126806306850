import React from 'react'
import propTypes from 'prop-types'
import './styles.scss'
import Collapse from '@mui/material/Collapse'

function PriceSelect({ label, price, oldPrice, details, offerDetail, selected, bubbleText }) {
  return (
    <div
      styleName={`price-select ${selected ? 'selected' : ''} ${bubbleText ? 'with-bubble' : ''}`}
    >
      <div styleName="texts">
        <div styleName="title">
          <span styleName="h3">{label}</span>
          <div styleName="price-details">
            {oldPrice && <span styleName="old-price">${oldPrice}</span>}
            <span styleName="price">
              ${price}
              {`${oldPrice ? '*' : ''}`}
            </span>
          </div>
        </div>
        <Collapse in={details && offerDetail && selected}>
          <p styleName="details">{details}</p>
          <p styleName="offer-details">{offerDetail}</p>
        </Collapse>
      </div>

      {bubbleText && <span styleName="bubble-text">{bubbleText}</span>}
    </div>
  )
}

PriceSelect.defaultProps = {
  label: 'One-time Purchase',
  price: 0
}

PriceSelect.propTypes = {
  label: propTypes.string,
  price: propTypes.number,
  oldPrice: propTypes.number,
  details: propTypes.string,
  offerDetail: propTypes.string,
  selected: propTypes.bool,
  bubbleText: propTypes.string
}

export default PriceSelect
