import React, { useRef } from 'react'
import './styles.scss'
import propTypes from 'prop-types'
import Slider from 'react-slick'
import Fade from '@mui/material/Fade'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import useWidth from '../../../../../hooks/useWidth'

const ProductImageSlider = ({ images }) => {
  const width = useWidth()
  const isMobile = width === 'xs' || width === 'sm'
  const sliderEl = useRef(null)

  const NextArrow = ({ onClick, style, highlight }) => {
    return (
      <div
        styleName={`nextArrow ${highlight ? 'highlight' : ''}`}
        onClick={onClick}
        style={{ ...style }}
      >
        <ArrowForwardIosIcon />
      </div>
    )
  }

  const PrevArrow = ({ onClick, style, highlight }) => {
    return (
      <div
        styleName={`prevArrow ${highlight ? 'highlight' : ''}`}
        onClick={onClick}
        style={{ ...style }}
      >
        <ArrowBackIosIcon />
      </div>
    )
  }

  const sliderSettings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: false,
    verticalSwiping: false,
    autoplay: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  }

  return (
    <div styleName="product-image-slider">
      <Fade in={!!width}>
        <div>
          <Slider ref={sliderEl} {...sliderSettings}>
            {images &&
              images.map((p, idx) => (
                <div key={idx} styleName="photo-wrap">
                  <div
                    styleName="photo"
                    style={{
                      backgroundImage: `url("${
                        isMobile ? p.image_path_mobile || p.image_path : p.image_path
                      }")`
                    }}
                  ></div>
                </div>
              ))}
          </Slider>
        </div>
      </Fade>
    </div>
  )
}

ProductImageSlider.propTypes = {
  images: propTypes.arrayOf(propTypes.object)
}

export default ProductImageSlider
