/* eslint-disable react/no-danger */
import './styles.scss'
import React from 'react'
import propTypes from 'prop-types'
import BeforeLaunchSection from './before-launch'

function EyecreamDuoWaitlistSection(props) {
  return (
    <div styleName="hero">
      <BeforeLaunchSection {...props} />
    </div>
  )
}

EyecreamDuoWaitlistSection.propTypes = {
  sectionId: propTypes.string,
  sectionNumber: propTypes.number,
  title1: propTypes.string,
  title2: propTypes.string,
  paragraph: propTypes.string,
  images: propTypes.array,
  notes: propTypes.string
}

export default EyecreamDuoWaitlistSection
