import React from 'react'
import { Box } from '@mui/material'
import { Builder, BuilderContent } from '@builder.io/react'
import { useBuilderResource } from '../../../hooks/useBuilderResource'
import { forceGetContentV2 } from '../../../services/builder-helper'
import { ProvenBuilderComponent } from '../../builder/ProvenBuilderComponent'
import PropTypes from 'prop-types'

function GenericBuilderSymbol({ symbolName, wrapperStyle, inputs }) {
  const { resource } = useBuilderResource(async () => {
    const symbolFomBuilder = await forceGetContentV2('symbol', {
      query: {
        name: symbolName
      },
      // NOTE: we use the Builder class here directly instead of the useIsPreviewing hook
      // because we need to know on the first render, whereas useIsPreviewing uses
      // React state, which can change, causing multiple fetches.
      //
      // Using Builder.isEditing || Builder.isPreviewing directly is considered bad practice
      // and can cause subtle race conditions, so if it becomes a problem, we should revisit.
      includeUnpublished: Builder.isEditing || Builder.isPreviewing
    })

    if (!symbolFomBuilder?.length) {
      return null
    }

    return symbolFomBuilder[0]
  }, [])

  const symbol = resource || null

  if (!symbol) return null

  const inputsAsObject = inputs.reduce((accOb, input) => {
    if (input.isReference && input.value && Array.isArray(input.value) && input.model) {
      accOb[input.name] = input.value.map(v => ({
        [input.model]: {
          '@type': '@builder.io/core:Reference',
          model: input.model,
          id: v.id,
          value: v
        }
      }))
    } else {
      accOb[input.name] = input.value
    }
    return accOb
  }, {})

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        mb: 3,
        ...(wrapperStyle || {})
      }}
    >
      <BuilderContent
        model="symbol"
        content={Builder.isEditing || Builder.isPreviewing ? null : symbol}
      >
        {(_data, _loading, fullData) => {
          return (
            <ProvenBuilderComponent
              model="symbol"
              content={fullData}
              options={{ includeRefs: true, options: { noTraverse: false } }}
              data={inputsAsObject}
              // reactOnlySharedData={{}} // TODO CHECK IF IT IS NEED IN THE SYMBOL
            />
          )
        }}
      </BuilderContent>
    </Box>
  )
}

GenericBuilderSymbol.defaultProps = {
  inputs: []
}

GenericBuilderSymbol.propTypes = {
  symbolName: PropTypes.string,
  wrapperStyle: PropTypes.object,
  inputs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
      isReference: PropTypes.bool,
      model: PropTypes.string //only makes sense when isReference: true
    })
  )
}

export default GenericBuilderSymbol
