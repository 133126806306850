import React, { useEffect } from 'react'
import './styles.scss'

function GiftCertificate() {
  useEffect(() => {
    ;(function (g, i, f) {
      i = g.createElement('script')
      f = g.getElementById('gift-up-target')
      i.async = 1
      i.src = 'https://cdn.giftup.app/dist/gift-up.js'
      f.parentNode.insertBefore(i, f)
    })(document)
  }, [])

  return (
    <section styleName="gift-certificates" id="giftCertificatesSection">
      <div styleName="container">
        <div styleName="content">
          <div
            id="gift-up-target"
            data-site-id="c3310e72-4dfd-422b-b042-97c90feeef68"
            data-platform="Other"
          />
        </div>
      </div>
    </section>
  )
}

export default GiftCertificate
