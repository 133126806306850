import React, { Component } from 'react'
import Helmet from 'react-helmet'
import { withRouter } from 'react-router-dom'
import propTypes from 'prop-types'
import { CDN_IMG } from '../../../constants/endpoints'
import { randomString } from '../../../utils/helpers'
import {
  provenMainUrl,
  provenTitle,
  provenDescription,
  provenOrganization
} from '../../../constants/seo-constants'

const SYSTEM_QUIZ_URLS = [
  '/quiz/skincare-quiz',
  'quiz/skincare-quiz',
  '/quiz/',
  '/quiz',
  'quiz',
  '/quiz/name/',
  'quiz/name/',
  '/quiz/concern',
  '/quiz/concern/'
]

const EYE_QUIZ_URLS = ['/quiz/eye', 'quiz/eye', 'quiz/eye/']

function addTrailingSlashIfNeed(url) {
  if (url && url[url.length - 1] !== '/') {
    return `${url}/`
  }

  return url
}

function absolutizeUrl(url) {
  let newUrl = url
  if (typeof url === 'string') {
    newUrl = url[0] === '/' ? `${provenMainUrl}${url}` : url
  }
  return addTrailingSlashIfNeed(newUrl)
}

function isSystemQuizUrl(url) {
  return SYSTEM_QUIZ_URLS.includes(url) || (url && url.startsWith('/quiz/'))
}

function isEyeQuizUrl(url) {
  return EYE_QUIZ_URLS.includes(url) || (url && url.startsWith('/quiz/eye/'))
}

function isBlogHomepageUrl(url) {
  return url === '/blog' || url === '/blog/'
}

function isBlogpost(url) {
  return url && url.startsWith('/blog/')
}

function isUrlHasHost(url) {
  let urlObj
  try {
    urlObj = new URL(url)
  } catch (_) {
    return false
  }
  return urlObj.protocol === 'http:' || urlObj.protocol === 'https:'
}

function getRelativePath(url) {
  if (isUrlHasHost(url)) {
    const urlObj = new URL(url)
    const rel = urlObj.toString().substring(urlObj.origin.length)
    return rel
  } else {
    return url
  }
}

export function getCanonicalUrlNotQuiz(url, seoCanonicalUrl) {
  if (seoCanonicalUrl) {
    return absolutizeUrl(seoCanonicalUrl)
  } else if (url) {
    const relativePath = getRelativePath(url)

    if (isEyeQuizUrl(relativePath) || isSystemQuizUrl(relativePath)) {
      return null
    } else {
      const result = relativePath.split('?')[0]
      const newUrl = `${provenMainUrl}${result}`
      return addTrailingSlashIfNeed(newUrl)
    }
  } else {
    return null
  }
}

export function getCanonicalUrlOrQuiz(url, seoCanonicalUrl) {
  if (seoCanonicalUrl) {
    return absolutizeUrl(seoCanonicalUrl)
  } else if (url) {
    const relativePath = getRelativePath(url)

    if (isEyeQuizUrl(relativePath)) {
      return `${provenMainUrl}${'/quiz/eye/'}`
    } else if (isSystemQuizUrl(relativePath)) {
      return `${provenMainUrl}${'/quiz/concern/'}`
    } else if (isBlogpost(url)) {
      const result = relativePath.split('?')[0]
      const newUrl = `${provenMainUrl}${result}`
      return addTrailingSlashIfNeed(newUrl)
    } else if (isBlogHomepageUrl(url)) {
      return `${provenMainUrl}${'/blog/'}`
    } else if (url === '/') {
      return `${provenMainUrl}/`
    } else {
      const result = relativePath.split('?')[0]
      const newUrl = `${provenMainUrl}${result}`
      return addTrailingSlashIfNeed(newUrl)
    }
  } else {
    return null
  }
}

/**
 * this component add a canonical url only if it came from outside or the url is a quiz url
 * for non quiz url it is handled in the /server/utils/seo.js in the SSR
 */
class DefaultSeo extends Component {
  render() {
    const {
      seoCanonicalUrl,
      seoUrl,
      seoTitle,
      seoDescription,
      seoImage,
      seoStructuredDataArr,
      externalScripts,
      location
    } = this.props

    const locationUrl = location && location.pathname ? location.pathname : ''
    const pageCanonicalUrl = getCanonicalUrlOrQuiz(locationUrl, seoCanonicalUrl)
    const seoCanonicalUrlAbsolute = absolutizeUrl(seoCanonicalUrl)
    const pageUrl = absolutizeUrl(seoUrl) || seoCanonicalUrlAbsolute

    const pageStructuredData =
      seoStructuredDataArr && seoStructuredDataArr.length && seoStructuredDataArr.length > 0
        ? seoStructuredDataArr
        : [provenOrganization]

    return (
      <Helmet>
        <meta
          name="google-site-verification"
          content="otRX2npiK_j23j8RnEz2lR1TYsczyFUWotu8b7QtW48"
        />
        {pageCanonicalUrl && <link href={pageCanonicalUrl} rel="canonical" />}
        <title>{seoTitle}</title>
        <meta name="title" content={seoTitle} />
        <meta name="description" content={seoDescription} />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:site_name" content="PROVEN Skincare" />
        <meta property="og:title" content={seoTitle} />
        <meta property="og:description" content={seoDescription} />
        <meta property="og:image" content={seoImage} />
        <meta name="twitter:site" content="@SkincareProven" />
        <meta name="twitter:title" content={seoTitle} />
        <meta name="twitter:description" content={seoDescription} />
        <meta name="twitter:image" content={seoImage} />
        <meta name="twitter:card" content="summary_large_image" />

        {pageStructuredData.map(sData => (
          <script key={randomString()} type="application/ld+json">
            {JSON.stringify(sData)}
          </script>
        ))}

        {externalScripts?.map(externalScript => (
          <script async key={randomString()} type="text/javascript" src={externalScript}></script>
        ))}
      </Helmet>
    )
  }
}

DefaultSeo.defaultProps = {
  seoTitle: provenTitle,
  seoDescription: provenDescription,
  seoImage: `${CDN_IMG}150x150%20logo.jpg`
}

DefaultSeo.propTypes = {
  seoCanonicalUrl: propTypes.string,
  seoUrl: propTypes.string,
  seoTitle: propTypes.string,
  seoDescription: propTypes.string,
  seoImage: propTypes.string,
  seoStructuredDataArr: propTypes.array,
  location: propTypes.object.isRequired,
  externalScripts: propTypes.array
}

export default withRouter(DefaultSeo)
