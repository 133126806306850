import React from 'react'
import { Box, Typography } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import ItemsMobile from './items-mobile'
import ItemsDesktop from './items-desktop'

const DATA_CY_PREFIX = 'evolve'
const DATA_CY_PREFIX_DESKTOP = `${DATA_CY_PREFIX}-desktop`
const DATA_CY_PREFIX_MOBILE = `${DATA_CY_PREFIX}-mobile`

/**
 *
 * @returns {JSX.Element|null}
 * @constructor
 */
function Evolution() {
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'))

  return (
    <Box
      sx={{
        position: 'relative',
        overflow: 'hidden',
        pt: 6,
        pb: { xs: 6, md: 24 },
        mx: { xs: -2, md: -8, lg: '-150px', xxl: '-10%' },
        zIndex: 1
      }}
    >
      <Typography
        component="p"
        variant="h2"
        sx={{
          maxWidth: 584,
          textAlign: 'center',
          px: { xs: 7.25, md: 0 },
          mx: 'auto',
          mb: { xs: 1, md: 3 }
        }}
        data-cy={
          isMdUp
            ? `${DATA_CY_PREFIX_DESKTOP}-welcome-message`
            : `${DATA_CY_PREFIX_MOBILE}-welcome-message`
        }
      >
        A Skincare System That Evolves With You
      </Typography>
      <Box>{isMdUp ? <ItemsDesktop /> : <ItemsMobile />}</Box>
    </Box>
  )
}

Evolution.propTypes = {}

export default Evolution
