/* eslint-disable react/no-danger */
import './styles.scss'
import React from 'react'
import propTypes from 'prop-types'
import ProductImageSlider from './product-image-slider'
import ProductPurchase from './product-purchase'

function BeforeLaunchSection({ images, ...props }) {
  return (
    <div styleName="before-launch">
      <div styleName="container">
        <ProductImageSlider images={images} />
        <ProductPurchase {...props} />
      </div>
    </div>
  )
}

BeforeLaunchSection.propTypes = {
  title1: propTypes.string,
  title2: propTypes.string,
  paragraph: propTypes.string,
  images: propTypes.array,
  notes: propTypes.string
}

export default BeforeLaunchSection
