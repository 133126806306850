import React, { Component } from 'react'
import propTypes from 'prop-types'
import './style.scss'
import { CDN_IMG } from '../../../constants/endpoints'

class Loading extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { pastDelay } = this.props
    let spinnerImg = `${CDN_IMG}transition/loading+spinner.gif`
    //let spinnerImg = `${CDN_IMG}transition/page-to-page-transition.gif`;

    return (
      <div styleName="container">
        <div>{pastDelay ? <img src={spinnerImg} alt="Results transition spinner" /> : null}</div>
      </div>
    )
  }
}

Loading.propTypes = {
  isLoading: propTypes.bool,
  pastDelay: propTypes.bool,
  timedOut: propTypes.bool,
  error: propTypes.object
}

export default Loading
