import React from 'react'
import Helmet from 'react-helmet'

function LandingPageSeo() {
  return (
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>
  )
}

export default LandingPageSeo
